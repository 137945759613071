/* eslint-disable no-unused-vars */
import { useRef } from 'react';
import './index.css';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'

const center_image = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
};

const lyrics = {
  FontFace: ('georgia'),
  fontSize: 40,
  color: '#dedede',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: '1'
};

function App() {
  const ref = useRef();
  return (
    <Parallax pages={3.75} ref={ref} style={{ top: '0', left: '0' }}>
      {/* FORTNITE GUY */}
      <ParallaxLayer sticky={{ start: 0.25, end: 3.1 }}>
        <img alt='jonesy jones' height='50%' margin='auto' src={require('./res/fortniteguy.png')}
          style={{
            justifyContent: 'center',
            position: 'relative',
            left: '40%',
          }} />
      </ParallaxLayer>

      {/* Overarching wallpaper */}
      <ParallaxLayer>
        <img alt="uh oh" src={require('./res/back.jpg')} width='100%' />
      </ParallaxLayer>

      {/* Tomato town */}
      <ParallaxLayer offset={0.99} speed={1.5} style={lyrics}>
        <h1>Just wiped out tomato town</h1>
      </ParallaxLayer>
      <ParallaxLayer offset={0.9} speed={1.1} style={center_image}>
        <img alt='' src={require('./res/tomatotown.jpg')} height='80%' width='80%' />
      </ParallaxLayer>

      {/* Pleasant park */}
      <ParallaxLayer offset={1.89} speed={1.5} style={lyrics}>
        <h1>Now we’re in the Pleasant Park streets</h1>
      </ParallaxLayer>
      <ParallaxLayer offset={1.8} speed={1.1} style={center_image}>
        <img alt='' src={require('./res/pleasantpark.jpg')} height='80%' width='80%' />
      </ParallaxLayer>

      {/* Moisty mire */}
      <ParallaxLayer offset={2.79} speed={1.5} style={lyrics}>
        <h1>You can take me to Moisty Mire, <br />but not Loot Lake</h1>
      </ParallaxLayer>
      <ParallaxLayer offset={2.7} speed={1.1} style={center_image}>
        <img alt='' src={require('./res/moistymire.jpg')} height='80%' width='80%' />
      </ParallaxLayer>
    </Parallax >
  );
}

export default App;